@import "./variables.scss";
@import "../modules/backoffice/components/partials/DataTable/DataTable.scss";
@import "../modules/backoffice/components/partials/DropdownMenu/DropdownMenu.scss";

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Segoe UI";
  background-color: $primaryDarkerColor;
  font-family: "SalesforceSans", "SalesforceSans-Light", "Roboto", "Open Sans", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  color: $contrastFontColor;
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 48px;
  opacity: 0.8;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: "SalesforceSans-Light", "Roboto", "Open Sans", sans-serif;
}

.website-content {
  width: 100%;
  height: calc(100vh - #{$headerSize});
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

@font-face {
  font-family: "SalesforceSans";
  src: url("./fonts/SalesforceSans-Regular.ttf");
}

@font-face {
  font-family: "SalesforceSans-Light";
  src: url("./fonts/SalesforceSans-Light.ttf");
}

button,
input {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  color: $contrastFontColor;
  font-family: "SalesforceSans", "Roboto", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.42rem;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;

  &:hover {
    cursor: pointer;
  }
  &:active {
    outline: 0;
    border: 0;
  }
  &:focus {
    outline: 0 !important;
  }
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button-rounded {
  background-color: $primaryLighterColor;
  padding: 11px 24px;
  text-transform: uppercase;
  color: $contrastFontColor !important;
  border-radius: 30px;

  &:hover {
    background-color: darken($primaryLighterColor, 10%);
  }
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="text"] {
  width: 100%;
}
