$primaryColor: #052e61;
$secondaryColor: #0000;
$tertiaryColor: #ffd420;
$contrastColor: #fff;

$primaryDarkerColor: #031e3f;
$primaryLighterColor: #009cdb;

$primaryFontColor: #424242;
$contrastFontColor: #ffffff;


$dangerColor: red;

$headerSize: 67px;
