@import "../../../styles/variables.scss";

.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .login-container {
    background-color: $contrastColor;
    width: 920px;
    height: 600px;
    border-radius: 4px;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    color: $primaryDarkerColor;
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
      0 16px 24px 2px rgba(0, 0, 0, 0.14);

    svg {
      height: 100%;
    }

    .account-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;

      h1 {
        font-size: 34px;
        margin-bottom: 56px;
      }

      .input-field {
        padding: 5px 16px;

        &:nth-child(even) {
          margin-bottom: 32px;
        }
      }

      .link-forgot-password {
        color: rgba($primaryFontColor, 0.5);
        margin-top: 16px;
        margin-bottom: 64px;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 1.5px;
        align-self: flex-start;
        text-decoration: none;

        &:hover {
          color: rgba($primaryFontColor, 0.3);

          cursor: pointer;
        }
      }

      .button-rounded {
        text-align: center;
        text-decoration: none;
        padding: 11px 45px;
        align-self: center;
        margin: 0;
      }
    }

    .password-display-toggle {
      cursor: pointer;
    }

    .error {
      margin-top: 24px;
      color: red;
    }
  }
}
