@import "../../../../../styles/variables.scss";

.dropdown-menu {
  z-index: 10;
  position: absolute;
  width: 162px;
  background-color: $contrastColor;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  .dropdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $primaryFontColor;
    font-weight: 600;
    letter-spacing: 0.1px;
    padding: 12px 14px;

    svg {
      width: 18px;
      margin-right: 12px;
      fill: $primaryFontColor;
    }

    &:hover {
      color: $primaryLighterColor;

      svg {
        fill: $primaryLighterColor;
      }
    }
  }
}
